import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetBooks($filters: BookAdminFilterInput, $id: String!) {
    booksAdmin(filters: $filters) {
      id
      title
      assignedToPackage(id: $id)
      authors {
        id
        name
      }
      genre {
        name
      }
      subgenre {
        name
      }
      conditions {
        name
      }
      labels {
        name
      }
      tags {
        id
        name
      }
      featuredFromDate
      featuredToDate
      expirationDate
      status
      isNew
    }
    bookTotalsAdmin {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetBook($id: String!) {
    bookAdmin(id: $id) {
      id
      title
      shortDescription
      authors {
        id
        name
      }
      genre {
        id
        name
        subgenres {
          id
          name
        }
      }
      subgenre {
        id
        name
      }
      conditions {
        id
        name
      }
      labels {
        id
        name
      }
      tags {
        id
        name
      }
      ebook {
        id
        name
      }
      bookCover {
        id
        url
      }
      audioBook {
        id
        name
      }
      bookDetails {
        publisher
        publishedOn
        eBookPages
        audioBookDuration
        ebookIsbn
        audioBookIsbn
        fullDescription
      }
      status
      featuredFromDate
      featuredToDate
      startDate
      expirationDate
      creationDate
      favoriteCount
      isNew
    }
  }
`;

const createMutation = gql`
  mutation CreateBook($input: CreateBookInput!) {
    createBook(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateBook($id: String!, $input: UpdateBookInput!) {
    updateBook(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteBook($id: String!) {
    deleteBook(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportBooks($filters: BookAdminFilterInput) {
    exportBooks(filters: $filters) {
      token
      link
    }
  }
`;

export default class BookApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportBooks',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.booksAdmin,
        total: data.bookTotalsAdmin,
      }),
    });
  }
}
