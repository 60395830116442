import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetTags($filters: TagFilterInput) {
    tags(filters: $filters) {
      id
      name
      rank
      status
      bookCount
      articleCount
      audioCount
      videoCount
    }
    tagTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetTag($id: String!) {
    tag(id: $id) {
      id
      name
      rank
      status
      bookCount
      articleCount
      audioCount
      videoCount
    }
  }
`;

const createMutation = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateTag($id: String!, $input: UpdateTagInput!) {
    updateTag(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteTag($id: String!) {
    deleteTag(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportTags($filters: TagAdminFilterInput) {
    exportTags(filters: $filters) {
      token
      link
    }
  }
`;

export default class TagApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportTags',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.tags,
        total: data.tagTotals,
      }),
    });
  }
}
