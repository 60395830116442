import { ENTITIES } from '../../../config/entities';
import AdminApolloService from './admin';
import ArticleApolloService from './article';
import AudioApolloService from './audio';
import AudioZoneApolloService from './audioZone';
import AuthApolloService from './auth';
import AuthorApolloService from './author';
import BannerApolloService from './banner';
import BookApolloService from './book';
import BookPackageApolloService from './bookPackage';
import BundleApolloService from './bundle';
import CompanyApolloService from './company';
import CompanyTagApolloService from './companyTag';
import ConditionApolloService from './condition';
import FaqApolloService from './faq';
import GenreApolloService from './genre';
import InternalDocumentService from './internalDocuments';
import LabelApolloService from './label';
import ProgramApolloService from './program';
import QuoteApolloService from './quote';
import TagApolloService from './tag';
import UserApolloService from './user';
import VideoApolloService from './video';

export const CompanyAPI = new CompanyApolloService(ENTITIES.company);
export const InternalDocumentAPI = new InternalDocumentService(ENTITIES.internalDocument);
export const ConditionAPI = new ConditionApolloService(ENTITIES.condition);
export const FaqAPI = new FaqApolloService(ENTITIES.faq);
export const GenreAPI = new GenreApolloService(ENTITIES.genre);
export const BookPackageAPI = new BookPackageApolloService(ENTITIES.bookPackage);
export const LabelAPI = new LabelApolloService(ENTITIES.label);
export const TagAPI = new TagApolloService(ENTITIES.tag);
export const VideoAPI = new VideoApolloService(ENTITIES.video);
export const AudioAPI = new AudioApolloService(ENTITIES.audio);
export const BundleAPI = new BundleApolloService(ENTITIES.bundle);
export const ArticleAPI = new ArticleApolloService(ENTITIES.article);
export const CompanyTagAPI = new CompanyTagApolloService(ENTITIES.companyTag);
export const BookAPI = new BookApolloService(ENTITIES.book);
export const AuthorAPI = new AuthorApolloService(ENTITIES.author);
export const QuoteAPI = new QuoteApolloService(ENTITIES.quote);
export const BannerAPI = new BannerApolloService(ENTITIES.banner);
export const ProgramAPI = new ProgramApolloService(ENTITIES.program);
export const UserAPI = new UserApolloService(ENTITIES.user);
export const AdminAPI = new AdminApolloService(ENTITIES.admin);
export const AuthAPI = new AuthApolloService();
export const AudioZoneAPI = new AudioZoneApolloService(ENTITIES.audioZone);
