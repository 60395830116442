import { gql } from '@apollo/client';

import ApolloService from '../service';

// export const CORE_BANNER_FIELDS = gql`
//   fragment CoreBannerFields on Banner {
//     id
//     name
//     rank
//     redirectLocation
//     status
//   }
// `;

const getAllQuery = gql`
  query GetBanners($filters: BannersFilterInput) {
    banners(filters: $filters) {
      id
      name
      rank
      redirectLocation
      status
      level
    }
    bannerTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetBanner($id: String!) {
    banner(id: $id) {
      id
      name
      level
      image {
        id
        url
      }
      redirectLocation
      url
      rank
      status
      videoId
      audioId
      articleId
      bookId
      programId
      authorId
      genreId
      conditionId
      labelId
    }
  }
`;

const createMutation = gql`
  mutation CreateBanner($input: CreateBannerInput!) {
    createBanner(input: $input) {
      id
      name
      imageId
      redirectLocation
      rank
      status
    }
  }
`;

const updateMutation = gql`
  mutation UpdateBanner($id: String!, $input: UpdateBannerInput!) {
    updateBanner(id: $id, input: $input) {
      id
      name
      imageId
      redirectLocation
      rank
      status
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteBanner($id: String!) {
    deleteBanner(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportBanners($filters: BannersFilterInput) {
    exportBanners(filters: $filters) {
      token
      link
    }
  }
`;

export default class BannerApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportBanners',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.banners,
        total: data.bannerTotals,
      }),
    });
  }
}
