import { isEmpty } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AdminAPI } from '../API/GraphQL/entities';
import { useAuth } from './auth';

const adminContext = createContext();

const useProviderAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [adminInfo] = useState({});
  const [profileInfo, setProfileInfo] = useState({});
  const [tempPassDialog, setTempPassDialog] = useState(false);

  const addAdmin = useCallback(
    async (body) => {
      await AdminAPI.post(body).then(() => navigate('/administrators'));
    },
    [navigate]
  );

  const getAdmin = useCallback(async () => {
    await AdminAPI.profile().then((res) => {
      setProfileInfo(res?.data?.adminProfile);
    });
  }, []);

  const updateAdmin = useCallback(
    async (type, adinId, body) => {
      const storage = JSON.parse(localStorage.getItem('token'));

      await AdminAPI.put({ ...body, id: type === 'profile' ? storage.id : adinId }).then(() => {
        getAdmin(type === 'profile' ? 'profile' : 'admin', type !== 'profile' && adinId);

        type !== 'profile' && navigate('/administrators');
      });
    },
    [getAdmin, navigate]
  );

  const changeMyPassword = useCallback(
    async (body) => {
      await AdminAPI.changePassword(body).then(() => {
        toast.success('You have successfully changed your password, please re-login');
        logout();
      });
    },
    [logout]
  );

  const setTempPassword = useCallback(async (body) => {
    await AdminAPI.setTempPassword(body).then(() => {
      setTempPassDialog(false);
      toast.success('You have successfully set temporary admin password');
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== '/create-password' && isEmpty(profileInfo)) {
      getAdmin();
    }
  }, [getAdmin, profileInfo, location.pathname]);

  return {
    getAdmin,
    addAdmin,
    adminInfo,
    profileInfo,
    updateAdmin,
    changeMyPassword,
    setTempPassword,
    tempPassDialog,
    setTempPassDialog,
    setProfileInfo,
  };
};

export const AdminProvider = ({ children }) => {
  const authProvider = useProviderAdmin();
  return <adminContext.Provider value={authProvider}>{children}</adminContext.Provider>;
};

export const useAdmin = () => {
  return useContext(adminContext);
};
