import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetArticles($filters: ArticleFilterInput) {
    articles(filters: $filters) {
      id
      title
      authors {
        id
        name
      }
      genre {
        name
      }
      subgenre {
        name
      }
      conditions {
        name
      }
      tags {
        id
        name
      }
      status
      featuredFromDate
      featuredToDate
      recommendedFromDate
      recommendedToDate
      expirationDate
      isNew
    }
    articleTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetArticle($id: String!) {
    article(id: $id) {
      id
      title
      body
      shortDescription
      authors {
        id
        name
      }
      genre {
        id
        name
        subgenres {
          id
          name
        }
      }
      subgenre {
        id
        name
      }
      conditions {
        id
        name
      }
      labels {
        id
        name
      }
      tags {
        id
        name
      }
      thumbnail {
        id
        url
      }
      headerImage {
        id
        url
      }
      headerImageId
      headerImageText
      footerImage {
        id
        url
      }
      audioFile {
        id
        name
        url
      }
      footerImageId
      footerImageUrl
      featuredFromDate
      featuredToDate
      recommendedFromDate
      recommendedToDate
      recommendedImage {
        id
        url
      }
      recommendedDescription
      startDate
      expirationDate
      publisher
      publishedOn
      readingTime
      status
      isNew
    }
  }
`;

const createMutation = gql`
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateArticle($id: String!, $input: UpdateArticleInput!) {
    updateArticle(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteArticle($id: String!) {
    deleteArticle(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportArticles($filters: ArticleAdminFilterInput) {
    exportArticles(filters: $filters) {
      token
      link
    }
  }
`;

export default class ArticleApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportArticles',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.articles,
        total: data.articleTotals,
      }),
    });
  }
}
