import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetCompanyTags($filters: CompanyTagFilterInput) {
    companyTags(filters: $filters) {
      id
      name
      rank
      status
      internalDocumentCount
      company {
        id
        name
      }
    }
    companyTagTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetCompanyTag($id: String!) {
    companyTag(id: $id) {
      id
      name
      rank
      status
      companyId
      internalDocumentCount
    }
  }
`;

const createMutation = gql`
  mutation CreateCompanyTag($input: CreateCompanyTagInput!) {
    createCompanyTag(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateCompanyTag($id: String!, $input: UpdateCompanyTagInput!) {
    updateCompanyTag(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteCompanyTag($id: String!) {
    deleteCompanyTag(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportCompanyTags($filters: CompanyTagFilterInput) {
    exportCompanyTags(filters: $filters) {
      token
      link
    }
  }
`;

export default class TagApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportCompanyTags',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.companyTags,
        total: data.companyTagTotals,
      }),
    });
  }
}
