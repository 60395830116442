import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { routers } from '../../config/routers';
import CreatePassword from '../../pages/CreatePassword';
import ForgotPassword from '../../pages/ForgotPassword';
import Login from '../../pages/Login';
import MyProfile from '../../pages/MyProfile';
import PageNotFound from '../../pages/PageNotFound';
import { useAdmin } from '../../store/admin';
import { useAuth } from '../../store/auth';

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useAdmin();
  const { logout } = useAuth();

  useEffect(() => {
    if (location.pathname !== '/' && localStorage.getItem('token') === null) {
      if (
        location.pathname === '/create-password' ||
        location.pathname === '/reset-password' ||
        location.pathname === '/forgot-password'
      ) {
        return;
      }

      logout('You have been logout');
      setProfileInfo({});
      navigate('/');
    }
  }, [location.pathname, logout, navigate]);

  const handleRouters = (profileInfo, item, key) => {
    if (item.permissions.includes(profileInfo.role)) {
      return <Route path={item.path} element={item.element} key={key} />;
    }
  };

  const routes = useMemo(() => routers.map((item, key) => handleRouters(profileInfo, item, key)), [profileInfo]);

  return (
    <>
      <ToastContainer className="toaster-container" position="bottom-left" autoClose={6000} />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/create-password" element={<CreatePassword />} />
        <Route exact path="/reset-password" element={<CreatePassword title="Reset password" />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/my-profile" element={<MyProfile />} />

        {routes}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};
export default AppLayout;
