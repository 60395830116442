import { makeStyles } from '@material-ui/core/styles';

import { PRIMARY_MAIN } from '../../styles/theme';

export const styles = makeStyles(() => ({
  nav: {
    height: '100%',
    width: '240px',
    overflow: 'auto',
    borderRight: '1px solid rgba(228, 228, 228, 1)',
  },
  link: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '14px 16px 14px 40px',
    transition: '0.2s',
    '&:hover': {
      color: PRIMARY_MAIN,
    },
  },
  linkIcon: {
    marginRight: 7,
    marginBottom: 4,
  },
  linkActive: {
    color: PRIMARY_MAIN,

    '&:before': {
      content: "''",
      position: 'absolute',
      top: '0',
      left: '0',
      width: '4px',
      height: '100%',
      backgroundColor: PRIMARY_MAIN,
    },
  },
  hr: {
    border: 0,
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
}));
