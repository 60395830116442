import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import { client } from './apollo';

export default class ApolloService {
  constructor(entity, config) {
    this.serviceName = entity?.name;

    this.getAllQuery = config.getAllQuery;
    this.getAllQueryResponseTransform = config.getAllQueryResponseTransform;
    this.getQuery = config.getQuery;
    this.createMutation = config.createMutation;
    this.updateMutation = config.updateMutation;
    this.deleteMutation = config.deleteMutation;
    this.exportMutation = config.exportMutation;
    this.exportKey = config.exportKey;
  }

  async post(input) {
    try {
      const res = await client.mutate({ mutation: this.createMutation, variables: { input } });

      toast.success(`${this.serviceName} successfully added`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} creating error: ${e?.message}`);
    }
  }

  async put(id, input) {
    try {
      const res = await client.mutate({ mutation: this.updateMutation, variables: { id, input } });
      toast.success(`${this.serviceName} successfully updated`);
      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} updating error: ${e?.message}`);
    }
  }

  async delete(id) {
    try {
      const res = await client.mutate({ mutation: this.deleteMutation, variables: { id } });

      toast.success(`${this.serviceName} successfully deleted`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} deleting error: ${e?.message}`);
    }
  }

  async updateStatus({ id, status }) {
    const res = await client.mutate({ mutation: this.updateMutation, variables: { id, input: { status } } });

    toast.success(`${this.serviceName} status successfully updated`);

    return res.data;
  }

  async get(id) {
    try {
      const res = await client.query({
        query: this.getQuery,
        variables: { id },
      });

      return {
        data: res.data,
      };
    } catch (e) {
      toast.error(`${this.serviceName} fetching error: ${e?.message}`);
    }
  }

  async getAll(params = {}, id = '') {
    try {
      const { sort, sortDir, filter, page, pageSize, search } = params;

      const res = await client.query({
        query: this.getAllQuery,
        variables: {
          filters: {
            page: page,
            pageSize: pageSize,
            filters: filter && !isEmpty(filter) ? filter : undefined,
            order: sort && sortDir ? { [sort]: sortDir } : undefined,
            search: search ? { [search.field]: search.value } : undefined,
          },
          id: id,
        },
      });

      return this.getAllQueryResponseTransform(res.data);
    } catch (e) {
      toast.error(`${this.serviceName} fetching error: ${e?.message}`);
    }
  }

  async exportAll(params = {}) {
    try {
      const { sort, sortDir, filter } = params;

      const res = await client.mutate({
        mutation: this.exportMutation,
        variables: {
          filters: {
            filters: filter ? filter : undefined,
            order: sort && sortDir ? { [sort]: sortDir } : undefined,
          },
        },
      });

      return {
        link: res.data[this.exportKey].link,
      };
    } catch (e) {
      toast.error(`${this.serviceName} export error: ${e?.message}`);
    }
  }
}
