import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import { useAdmin } from '../../store/admin';
import { useAuth } from '../../store/auth';
import { styles } from './styles';

const Header = () => {
  const classes = styles();
  const { logout } = useAuth();
  const { profileInfo } = useAdmin();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <header className={classes.header}>
      <img className={classes.logo} src={logo} alt="" />

      <Button
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className={clsx(classes.btn, open && classes.btnOpened)}
      >
        {profileInfo.firstName} {profileInfo.lastName}
      </Button>
      <Menu className={classes.menu} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Link to="/my-profile">My profile</Link>
        </MenuItem>
        <MenuItem>
          <button onClick={handleLogout}>Log out</button>
        </MenuItem>
      </Menu>
    </header>
  );
};
export default Header;
