import { isUndefined } from 'lodash';

import { ENTITIES } from './entities';

const MENU = [
  ENTITIES.company,
  ENTITIES.bookPackageCompany,
  ENTITIES.bundle,
  ENTITIES.internalDocument,
  ENTITIES.user,
  ENTITIES.B2Cuser,
  ENTITIES.program,
  undefined,
  ENTITIES.book,
  ENTITIES.article,
  ENTITIES.video,
  ENTITIES.audio,
  ENTITIES.audioZone,
  ENTITIES.quote,
  ENTITIES.tag,
  ENTITIES.author,
  ENTITIES.genre,
  ENTITIES.condition,
  ENTITIES.label,
  ENTITIES.companyTag,
  ENTITIES.banner,
  undefined,
  ENTITIES.faq,
  ENTITIES.admin,
];

const buildItem = (item) => ({
  href: item.listRoute,
  title: item.listTitle,
  titleHint: item.titleHint,
  adminModule: item.module,
  icon: item.icon,
  data: item,
});

export const menu = MENU.map((item) => {
  if (isUndefined(item)) return item;

  return buildItem(item);
});
