import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetQuotes($filters: QuoteFilterInput) {
    quotes(filters: $filters) {
      id
      title
      type
      author {
        id
        name
      }
      thumbnail {
        id
        url
      }
      genre {
        id
        name
      }
      conditions {
        id
        name
      }
      status
      startDate
      expirationDate
      isNew
    }
    quoteTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetQuote($id: String!) {
    quote(id: $id) {
      id
      title
      type
      quote
      author {
        id
        name
      }
      thumbnail {
        id
        url
      }
      genre {
        id
        name
      }
      conditions {
        id
        name
      }
      quote
      backgroundImage {
        id
        url
      }
      videoFile {
        id
        url
      }
      videoUrl
      startDate
      expirationDate
      status
      isNew
    }
  }
`;

const createMutation = gql`
  mutation CreateQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateQuote($id: String!, $input: UpdateQuoteInput!) {
    updateQuote(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteQuote($id: String!) {
    deleteQuote(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportQuotes($filters: QuoteAdminFilterInput) {
    exportQuotes(filters: $filters) {
      token
      link
    }
  }
`;

export default class QuoteApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportQuotes',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.quotes,
        total: data.quoteTotals,
      }),
    });
  }
}
