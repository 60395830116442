import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import AutoStoriesIcon from '@mui/icons-material/AutoStoriesOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import FilePresentIcon from '@mui/icons-material/FilePresentOutlined';
import Filter4OutlinedIcon from '@mui/icons-material/Filter4Outlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import StyleIcon from '@mui/icons-material/StyleOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
// module - permission name of entity in DB
// api - name of entity for URL building (only for REST API)
// name - name of entity for notifications
// listTitle - title for page with list of entities
// listRoute - url for page with list of entities

export const ENTITIES = {
  company: {
    module: 'Companies',
    name: 'Company',
    listTitle: 'Companies',
    listRoute: '/companies',
    addRoute: '/add-company',
    addTitle: 'Add company',
    editRoute: '/company/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: BusinessIcon,
  },

  internalDocument: {
    module: 'Documents',
    name: 'Document',
    listTitle: 'Documents',
    listRoute: '/documents',
    addRoute: '/add-document',
    addTitle: 'Add document',
    editRoute: '/document/:id',
    permissions: ['ROOT', 'ADMIN', 'COMPANY_ADMIN'],
    icon: FilePresentIcon,
  },

  condition: {
    module: 'Conditions',
    name: 'Condition',
    listTitle: 'Conditions',
    listRoute: '/conditions',
    addRoute: '/add-condition',
    addTitle: 'Add condition',
    editRoute: '/condition/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: SettingsAccessibilityOutlinedIcon,
    titleHint: 'TriggerToolkits',
  },

  faq: {
    module: 'FAQ',
    name: 'Question',
    listTitle: 'FAQ',
    listRoute: '/faq',
    addRoute: '/add-faq',
    addTitle: 'Add question',
    editRoute: '/faq/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: HelpCenterOutlinedIcon,
  },

  genre: {
    module: 'Themes',
    name: 'Theme',
    listTitle: 'Themes',
    listRoute: '/themes',
    addRoute: '/add-theme',
    addTitle: 'Add theme',
    editRoute: '/theme/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: TypeSpecimenOutlinedIcon,
    titleHint: 'TriggerThemes',
  },

  quote: {
    module: 'Quotes',
    name: 'Quote',
    listTitle: 'Quotes',
    listRoute: '/quotes',
    addRoute: '/add-quote',
    addTitle: 'Add quote',
    editRoute: '/quote/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: SpeakerNotesOutlinedIcon,
  },

  bookPackage: {
    module: 'BookPackages',
    name: 'Package',
    listTitle: 'Packages',
    listRoute: '/packages',
    addRoute: '/add-package',
    addTitle: 'Add package',
    editRoute: '/package/:id',
    permissions: ['ROOT', 'ADMIN', 'COMPANY_ADMIN'],
    icon: Inventory2OutlinedIcon,
  },

  bundle: {
    module: 'Bundles',
    name: 'Bundle',
    listTitle: 'Bundles',
    listRoute: '/bundles',
    addRoute: '/add-bundle',
    addTitle: 'Add bundle',
    editRoute: '/bundle/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: Inventory2OutlinedIcon,
  },

  bookPackageCompany: {
    module: 'BookPackages',
    name: 'Package',
    listTitle: 'Packages',
    listRoute: '/packages',
    editRoute: '/package/:id',
    permissions: ['COMPANY_ADMIN'],
    icon: Inventory2OutlinedIcon,
  },

  book: {
    module: 'Books',
    name: 'Book',
    listTitle: 'Books',
    listRoute: '/books',
    addRoute: '/add-book',
    addTitle: 'Add book',
    editRoute: '/book/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: AutoStoriesIcon,
  },

  video: {
    module: 'Videos',
    name: 'Video',
    listTitle: 'Videos',
    listRoute: '/videos',
    addRoute: '/add-video',
    addTitle: 'Add video',
    editRoute: '/video/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: VideoFileOutlinedIcon,
  },

  audio: {
    module: 'Audios',
    name: 'Audio',
    listTitle: 'Audios',
    listRoute: '/audios',
    addRoute: '/add-audio',
    addTitle: 'Add audio',
    editRoute: '/audio/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: AudioFileOutlinedIcon,
  },

  audioZone: {
    module: 'AudioZone',
    name: 'AudioZone',
    listTitle: 'AudioZone',
    listRoute: '/audioZone',
    editRoute: '/audioZone/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: SpatialTrackingIcon,
    titleHint: 'AudioTherapy',
  },

  article: {
    module: 'Articles',
    name: 'Article',
    listTitle: 'Articles',
    listRoute: '/articles',
    addRoute: '/add-article',
    addTitle: 'Add article',
    editRoute: '/article/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: ArticleOutlinedIcon,
  },

  program: {
    module: 'Programs',
    name: 'Program',
    listTitle: 'Programs',
    listRoute: '/programs',
    addRoute: '/add-program',
    addTitle: 'Add program',
    editRoute: '/program/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: Filter4OutlinedIcon,
  },

  author: {
    module: 'Authors',
    name: 'Author',
    listTitle: 'Authors',
    listRoute: '/authors',
    addRoute: '/add-author',
    addTitle: 'Add author',
    editRoute: '/author/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: RecordVoiceOverOutlinedIcon,
    titleHint: 'TriggerVoices',
  },

  companyWorker: {
    module: 'CompanyWorkers',
    name: 'CompanyWorker',
  },

  banner: {
    module: 'Banners',
    name: 'Banner',
    listTitle: 'Banners carousel',
    listRoute: '/banners',
    addRoute: '/add-banner',
    addTitle: 'Add banner',
    editRoute: '/banner/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: ViewCarouselOutlinedIcon,
  },

  label: {
    module: 'Labels',
    name: 'Label',
    listTitle: 'Labels',
    listRoute: '/labels',
    addRoute: '/add-label',
    addTitle: 'Add label',
    editRoute: '/label/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: BookmarksOutlinedIcon,
  },

  tag: {
    module: 'Tags',
    name: 'Tag',
    listTitle: 'Tags',
    listRoute: '/tags',
    addRoute: '/add-tag',
    addTitle: 'Add tag',
    editRoute: '/tag/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: BookIcon,
  },

  companyTag: {
    module: 'CompanyTags',
    name: 'Company tag',
    listTitle: 'Company tags',
    listRoute: '/company-tags',
    addRoute: '/add-company-tag',
    addTitle: 'Add company tag',
    editRoute: '/company-tag/:id',
    permissions: ['ROOT', 'ADMIN', 'COMPANY_ADMIN'],
    icon: StyleIcon,
  },

  user: {
    module: 'Users',
    name: 'B2B User',
    listTitle: 'B2B Users',
    listRoute: '/users',
    editRoute: '/user/:id',
    permissions: ['ROOT', 'ADMIN', 'COMPANY_ADMIN'],
    icon: GroupIcon,
  },

  B2Cuser: {
    module: 'B2CUsers',
    name: 'B2C User',
    listTitle: 'B2C Users',
    listRoute: '/b2c-users',
    editRoute: '/b2c-user/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: SupervisorAccountOutlinedIcon,
  },

  admin: {
    module: 'Superadmins',
    name: 'Superadmin',
    api: 'Superadmin',
    listTitle: 'Superadmins',
    listRoute: '/admins',
    addRoute: '/add-admin',
    addTitle: 'Add superadmin',
    editRoute: '/admin/:id',
    permissions: ['ROOT', 'ADMIN'],
    icon: AdminPanelSettingsOutlinedIcon,
  },
};
