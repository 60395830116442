import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  pageTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 32px 0',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  buttons: {
    display: 'flex',
    gap: '20px',
  },
  // link: {
  //   minWidth: '220px',
  //   color: '#fff',
  //   backgroundColor: 'rgb(29, 166, 220)',
  //   borderRadius: '5px',
  //   padding: '16px',
  //   textAlign: 'center',
  //   transition: '0.2s',
  //   '&:hover': {
  //     backgroundColor: 'rgb(27 159 211)',
  //   },
  // },
}));
