import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetInternalDocuments($filters: InternalDocumentFilterInput) {
    internalDocuments(filters: $filters) {
      id
      title
      shortDescription
      documentId
      companyId
      companyTags {
        name
      }
      company {
        name
      }
      documentCoverId
      rank
      status
    }
    internalDocumentTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetInternalDocument($id: String!) {
    internalDocument(id: $id) {
      id
      title
      shortDescription
      documentId
      companyTags {
        id
        name
      }
      document {
        id
        name
        url
      }
      companyId
      documentCoverId
      documentCover {
        id
        url
        name
      }
      rank
      status
    }
  }
`;

const createMutation = gql`
  mutation CreateInternalDocument($input: CreateInternalDocumentInput!) {
    createInternalDocument(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateInternalDocument($id: String!, $input: UpdateInternalDocumentInput!) {
    updateInternalDocument(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteInternalDocument($id: String!) {
    deleteInternalDocument(id: $id) {
      id
    }
  }
`;

export default class InternalDocumentApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.internalDocuments,
        total: data.internalDocumentTotals,
      }),
    });
  }
}
