import AccountCircle from '@mui/icons-material/AccountCircle';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import Button from '../../components/UI/Button';
import { useAuth } from '../../store/auth';
import { styles } from './styles';

const ForgotPassword = () => {
  const classes = styles();
  const navigate = useNavigate();
  const { sendResetPasswordEmail } = useAuth();
  const [form, setForm] = useState({
    email: '',
  });

  const handleChange = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value });
  };

  const handleForm = (e) => {
    e.preventDefault();

    sendResetPasswordEmail({ email: form.email }).then(() => {
      navigate('/');
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginForm}>
        <img className={classes.logo} src={logo} alt="" />
        <h1 className={classes.title}>Forgot password</h1>
        <form className={classes.form} onSubmit={handleForm} action="">
          <FormControl variant="filled">
            <FilledInput
              type="email"
              placeholder="Enter your email"
              className={classes.input}
              onChange={handleChange('email')}
              required
              autoComplete="user"
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
          </FormControl>
          <Button className={classes.btn} type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};
export default ForgotPassword;
