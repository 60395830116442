import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetFAQs($filters: FAQFilterInput) {
    faqs(filters: $filters) {
      id
      category
      question
      answer
      status
    }
    faqTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetFAQ($id: String!) {
    faq(id: $id) {
      id
      category
      question
      answer
      status
    }
  }
`;

const createMutation = gql`
  mutation CreateFAQ($input: CreateFAQInput!) {
    createFAQ(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateFAQ($id: String!, $input: UpdateFAQInput!) {
    updateFAQ(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteFAQ($id: String!) {
    deleteFAQ(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportFAQs($filters: FAQFilterInput) {
    exportFAQs(filters: $filters) {
      token
      link
    }
  }
`;

export default class FaqApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportFAQs',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.faqs,
        total: data.faqTotals,
      }),
    });
  }
}
