import { ENTITIES } from '../config/entities';
import {
  AdminAPI,
  ArticleAPI,
  AudioAPI,
  AudioZoneAPI,
  AuthorAPI,
  BannerAPI,
  BookAPI,
  BookPackageAPI,
  BundleAPI,
  CompanyAPI,
  CompanyTagAPI,
  ConditionAPI,
  FaqAPI,
  GenreAPI,
  InternalDocumentAPI,
  LabelAPI,
  ProgramAPI,
  QuoteAPI,
  TagAPI,
  UserAPI,
  VideoAPI,
} from './GraphQL/entities';

export const API = {
  [ENTITIES.genre.name]: GenreAPI,
  [ENTITIES.faq.name]: FaqAPI,
  [ENTITIES.company.name]: CompanyAPI,
  [ENTITIES.internalDocument.name]: InternalDocumentAPI,
  [ENTITIES.condition.name]: ConditionAPI,
  [ENTITIES.bookPackage.name]: BookPackageAPI,
  [ENTITIES.label.name]: LabelAPI,
  [ENTITIES.tag.name]: TagAPI,
  [ENTITIES.video.name]: VideoAPI,
  [ENTITIES.audio.name]: AudioAPI,
  [ENTITIES.article.name]: ArticleAPI,
  [ENTITIES.bundle.name]: BundleAPI,
  [ENTITIES.companyTag.name]: CompanyTagAPI,
  [ENTITIES.book.name]: BookAPI,
  [ENTITIES.author.name]: AuthorAPI,
  [ENTITIES.banner.name]: BannerAPI,
  [ENTITIES.user.name]: UserAPI,
  [ENTITIES.B2Cuser.name]: UserAPI,
  [ENTITIES.admin.name]: AdminAPI,
  [ENTITIES.program.name]: ProgramAPI,
  [ENTITIES.quote.name]: QuoteAPI,
  [ENTITIES.audioZone.name]: AudioZoneAPI,
};
