import React from 'react';

import Header from '../../components/Header';
import LoadingBox from '../../components/LoadingBox';
import Nav from '../../components/Nav';
import { styles } from './styles';

const PageLayout = ({ isModal = false, loading = false, children }) => {
  const classes = styles();

  return isModal ? (
    <main className={classes.mainModal}>{loading ? <LoadingBox /> : children}</main>
  ) : (
    <>
      <Header />
      <div className={classes.root}>
        <Nav />
        <main className={classes.main}>{loading ? <LoadingBox /> : children}</main>
      </div>
    </>
  );
};
export default PageLayout;
