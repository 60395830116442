import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetLabels($filters: LabelAdminFilterInput) {
    labelsAdmin(filters: $filters) {
      id
      name
      status
      bookCount
      articleCount
      audioCount
      videoCount
    }
    labelTotalsAdmin {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetLabel($id: String!) {
    labelAdmin(id: $id) {
      id
      name
      status
      bookCount
      articleCount
      audioCount
      videoCount
    }
  }
`;

const createMutation = gql`
  mutation CreateLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateLabel($id: String!, $input: UpdateLabelInput!) {
    updateLabel(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteLabel($id: String!) {
    deleteLabel(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportLabels($filters: LabelAdminFilterInput) {
    exportLabels(filters: $filters) {
      token
      link
    }
  }
`;

export default class LabelApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportLabels',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.labelsAdmin,
        total: data.labelTotalsAdmin,
      }),
    });
  }
}
