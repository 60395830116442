import { gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { client } from '../apollo';

const updateMutation = gql`
  mutation UpdateAudioZone($input: UpdateAudioZoneInput!) {
    updateAudioZone(input: $input) {
      tag {
        id
        name
      }
      condition {
        id
        name
      }
      section {
        title
        subTitle
        backgroundImage {
          id
          url
        }
        audio {
          id
          title
        }
        video {
          id
          title
        }
      }
    }
  }
`;

const getQuery = gql`
  query GetAudioZone {
    audioZone {
      tag {
        id
        name
      }
      condition {
        id
        name
      }
      section {
        title
        subTitle
        backgroundImage {
          id
          url
        }
        audio {
          id
          title
        }
        video {
          id
          title
        }
      }
    }
  }
`;

export default class AudioZoneApolloService {
  async get() {
    try {
      const res = await client.query({ query: getQuery });

      return {
        data: res.data,
      };
    } catch (e) {
      toast.error(`AudioZone fetching error: ${e?.message}`);
    }
  }

  async update(data) {
    try {
      const res = await client.mutate({
        mutation: updateMutation,
        variables: { input: data },
      });

      toast.success(`AudioZone successfully updated`);

      return { data: res.data };
    } catch (e) {
      toast.error(`AudioZone update error: ${e?.message}`);
    }
  }
}
