import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(228, 228, 228, 1)',
    padding: '0 32px 0 16px',
  },
  logo: {
    margin: '10px',
    width: 'auto',
    height: '45px',
  },
  btn: {
    '&.MuiButtonBase-root': {
      color: '#000',
      textTransform: 'initial',
    },
  },
  btnOpened: {
    '& .MuiButton-endIcon': {
      transform: 'scale(1, -1)',
    },
  },
  menu: {
    '& .MuiList-root': {
      minWidth: '150px',
    },
    '& .MuiButtonBase-root': {
      padding: '0',
      '& a, & button': {
        width: '100%',
        padding: '6px 16px',
        textAlign: 'left',
      },
    },
  },
}));
