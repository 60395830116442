import Button from '@mui/material/Button';
import React from 'react';

const CustomButton = ({
  children,
  type,
  size = 'large',
  variant = 'contained',
  onClick,
  disabled,
  loading,
  startIcon,
  className,
}) => {
  return (
    <Button
      className={className}
      startIcon={startIcon}
      size={size}
      type={type}
      loading={loading}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
export default CustomButton;
