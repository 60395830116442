import React from 'react';

import { styles } from './styles';

const ConfirmStickySection = ({ children, isModal = false }) => {
  const classes = styles();

  return <div className={isModal ? classes.confirmSectionAbsolute : classes.confirmSection}>{children}</div>;
};
export default ConfirmStickySection;
