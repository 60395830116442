import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { API } from '../../API';
import ConfirmStickySection from '../../components/ConfirmStickySection';
import PageTitle from '../../components/PageTitle';
import InputRow from '../../components/Rows/InputRow';
import Button from '../../components/UI/Button';
import { ENTITIES } from '../../config/entities';
import PageLayout from '../../layout/PageLayout';
import { useAdmin } from '../../store/admin';
import { styles } from './styles';

const MyProfile = () => {
  const entity = ENTITIES.admin;
  const classes = styles();
  const navigation = useNavigate();
  const { profileInfo, getAdmin } = useAdmin();
  const isRoot = profileInfo.role === 'ROOT';

  const { control, reset, handleSubmit, watch } = useForm({ defaultValues: {} });
  const oldPassword = watch('oldPassword');
  const newPassword = watch('newPassword');

  useEffect(() => {
    if (profileInfo) {
      const { email, firstName, lastName } = profileInfo;
      reset({
        email,
        firstName,
        lastName,
      });
    }
  }, [profileInfo]);

  const onSubmit = (data) => {
    const form = {
      firstName: data.firstName,
      lastName: data.lastName,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };

    API[entity.name].updateProfile(form).then(() => {
      getAdmin();
      navigation(entity.listRoute);
    });
  };

  return (
    <PageLayout>
      <PageTitle title="My profile" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.myProfileBox}>
          <h3 className={classes.title}>Contact information</h3>
          <div>
            <InputRow title="Name" control={control} name="firstName" props={{ required: true, disabled: isRoot }} />
            <InputRow title="Surname" control={control} name="lastName" props={{ required: true, disabled: isRoot }} />
            <InputRow title="Email" control={control} name="email" props={{ disabled: true }} />
          </div>
          <h3 className={classes.title}>Password</h3>
          <div className={classes.passBox}>
            <div className={classes.passCol}>
              <InputRow
                title="Old password"
                control={control}
                name="oldPassword"
                props={{ type: 'password', required: oldPassword || newPassword, disabled: isRoot }}
              />
              <InputRow
                title="New password"
                control={control}
                name="newPassword"
                props={{ type: 'password', required: oldPassword || newPassword, disabled: isRoot }}
              />
              {/* <InputRow
                title="Confirm new password"
                control={control}
                name="confirmedPassword"
                props={{ type: 'password', required: false, disabled: isRoot }}
              /> */}
            </div>
          </div>
        </div>
        <ConfirmStickySection>
          <Button disabled={isRoot} type="submit" startIcon={<SaveIcon />}>
            Save
          </Button>
        </ConfirmStickySection>
      </form>
    </PageLayout>
  );
};
export default MyProfile;
