import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetBundles($filters: BundleFilterInput) {
    bundles(filters: $filters) {
      id
      name
      packageType
      companyCount
    }
    bundleTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetBundle($id: String!) {
    bundle(id: $id) {
      id
      name
      articles {
        id
        title
      }
      audios {
        id
        title
      }
      books {
        id
        title
      }
      videos {
        id
        title
      }
      conditions {
        id
        name
      }
      genres {
        id
        name
      }
      subgenres {
        id
        name
      }
      companyCount
      packageType
    }
  }
`;

const createMutation = gql`
  mutation CreateBundle($input: CreateBundleInput!) {
    createBundle(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateBundle($id: String!, $input: UpdateBundleInput!) {
    updateBundle(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteBundle($id: String!) {
    deleteBundle(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportBundles($filters: BundleAdminFilterInput) {
    exportBundles(filters: $filters) {
      token
      link
    }
  }
`;

export default class BundleApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportBundles',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.bundles,
        total: data.bundleTotals,
      }),
    });
  }
}
