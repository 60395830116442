import { gql } from '@apollo/client';

import { client } from '../apollo';
import ApolloService from '../service';

const getAllQuery = gql`
  query GetGenres($filters: GenreAdminFilterInput) {
    genresAdmin(filters: $filters) {
      id
      name
      subgenres {
        id
        name
        genreId
      }
      isMonth
      isAudioZone
      bookCount
      articleCount
      audioCount
      videoCount
      status
    }
    genreTotalsAdmin {
      pageCount
      count
    }
  }
`;

const getGenresQuery = gql`
  query GetGenres($filters: GenreFilterInput) {
    genres(filters: $filters) {
      id
      name
      subgenres {
        id
        name
        genreId
      }
      isMonth
      isAudioZone
      status
    }
  }
`;

const getQuery = gql`
  query GetGenre($id: String!) {
    genreAdmin(id: $id) {
      id
      name
      subgenres {
        id
        name
        genreId
      }
      image {
        id
        url
      }
      isMonth
      isAudioZone
      status
      bookCount
      articleCount
      audioCount
      videoCount
    }
  }
`;

const createMutation = gql`
  mutation CreateGenre($input: CreateGenreInput!) {
    createGenre(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateGenre($id: String!, $input: UpdateGenreInput!) {
    updateGenre(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteGenre($id: String!) {
    deleteGenre(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportGenres($filters: GenreAdminFilterInput) {
    exportGenres(filters: $filters) {
      token
      link
    }
  }
`;

export default class GenreApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportGenres',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.genresAdmin,
        total: data.genreTotalsAdmin,
      }),
    });
  }

  async getGenres() {
    const res = await client.query({ query: getGenresQuery });

    return {
      data: res.data.genres,
    };
  }
}
