import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  input: {
    '&.lg': {
      width: '480px',
    },
    '&.lg_button': {
      width: '370px',
    },
    '&.md': {
      width: '190px',
    },
    '&.sm': {
      width: '82px',
    },
    '& .MuiInputBase-root': {
      padding: '0',
      transition: '0.2s',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '12px 14px',
    },
  },
}));
