import { gql } from '@apollo/client';

import { client } from '../apollo';
import ApolloService from '../service';

const getAllQuery = gql`
  query GetCompanies($filters: CompanyAdminFilterInput) {
    companiesAdmin(filters: $filters) {
      id
      name
      admins {
        email
      }
      salesManager {
        id
        name
      }
      activeUserCount
      packageCountTotal
      packageStartDate
      packageExpirationDate
      activePackageCount
    }
    companyTotalsAdmin {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetCompany($id: String!) {
    companyAdmin(id: $id) {
      id
      name
      contactName
      contactEmail
      salesManager {
        id
        name
      }
      notes
      expirationDate
      internalDocuments {
        id
        title
        status
      }
      companyTags {
        id
        name
      }
      banners {
        id
        name
        rank
        image {
          id
          url
        }
        imageId
        url
        redirectLocation
        status
        videoId
        audioId
        articleId
        bookId
      }
      admins {
        email
      }
      bookPackages {
        id
        name
      }
      activeUserCount
      packageCountTotal
      activePackageCount
      supports {
        id
        type
        title
        description
        value
        rank
      }
      logo {
        id
        url
      }
    }
  }
`;

const createMutation = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateCompany($id: String!, $input: UpdateCompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteCompany($id: String!) {
    deleteCompany(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportCompanies($filters: CompanyAdminFilterInput) {
    exportCompanies(filters: $filters) {
      token
      link
    }
  }
`;

const getSalesManagersQuery = gql`
  query GetSalesManagers {
    salesManagers {
      id
      name
    }
  }
`;

export default class CompanyApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportCompanies',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.companiesAdmin,
        total: data.companyTotalsAdmin,
      }),
    });
  }

  async getSalesManagers() {
    const res = await client.query({ query: getSalesManagersQuery });

    return {
      data: res.data.salesManagers,
    };
  }
}
