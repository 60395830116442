import { gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { client } from '../apollo';
import ApolloService from '../service';

const getAllQuery = gql`
  query GetAdmins($filters: AdminFilterInput) {
    superAdmins(filters: $filters) {
      id
      email
      firstName
      lastName
      status
    }
    superAdminTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetAdmin($id: String!) {
    admin(id: $id) {
      id
      email
      firstName
      lastName
      status
    }
  }
`;

const createMutation = gql`
  mutation CreateAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      id
      email
      firstName
      lastName
      status
    }
  }
`;

const updateMutation = gql`
  mutation UpdateAdmin($id: String!, $input: UpdateAdminInput!) {
    updateAdmin(id: $id, input: $input) {
      id
      email
      firstName
      lastName
      status
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteAdmin($id: String!) {
    deleteAdmin(id: $id) {
      id
    }
  }
`;

const profileQuery = gql`
  query GetProfile {
    adminProfile {
      id
      email
      firstName
      lastName
      role
      status
      selectedCompanyId
    }
  }
`;

const updateProfileMutation = gql`
  mutation UpdateProfile($input: UpdateAdminProfileInput!) {
    updateAdminProfile(input: $input) {
      id
      email
      firstName
      lastName
      status
    }
  }
`;

const exportMutation = gql`
  mutation ExportAdmins($filters: AdminFilterInput) {
    exportSuperAdmins(filters: $filters) {
      token
      link
    }
  }
`;

export default class AdminApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportSuperAdmins',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.superAdmins,
        total: data.superAdminTotals,
      }),
    });
  }

  async profile() {
    const res = await client.query({ query: profileQuery });

    return {
      data: res.data,
    };
  }

  async updateProfile(input) {
    const res = await client.mutate({ mutation: updateProfileMutation, variables: { input } });

    toast.success(`Your profile was successfully updated`);

    return {
      data: res.data,
    };
  }
}
