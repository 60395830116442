import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  titleRow: {
    width: '160px',
    fontWeight: '700',
    fontSize: '14px',
    padding: '12px 0',
    '& p': {
      fontWeight: '400',
      fontSize: '14px',
      color: '#CCCCCC',
      margin: '4px 0 0 0',
    },
    '& span': {
      fontWeight: '400',
      fontSize: '14px',
      color: '#CCCCCC',
      margin: '4px 0 0 0',
    },
  },
}));
