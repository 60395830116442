import React, { createContext, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthAPI } from '../API/GraphQL/entities';

const authContext = createContext();

const useProviderAuth = () => {
  const navigate = useNavigate();

  const login = useCallback(
    async (values) => {
      return await AuthAPI.login(values).then((res) => {
        const token = res?.data?.login?.accessToken;
        localStorage.setItem('token', token);
      });
    },
    [navigate]
  );

  const logout = useCallback(async () => {
    localStorage.removeItem('token');
    navigate('/');
  }, [navigate]);

  const createPassword = useCallback(
    async (data) => {
      return await AuthAPI.createPassword(data).then((res) => {
        return res.data;
      });
    },
    [navigate]
  );

  const sendResetPasswordEmail = useCallback(
    async (data) => {
      return await AuthAPI.sendResetPasswordEmail(data).then((res) => {
        return res.data;
      });
    },
    [navigate]
  );

  // useEffect(() => {
  //   // Session token should expire in 6 hours
  //   const storage = localStorage.getItem('token');
  //   if (moment().unix() > storage?.expires) {
  //     console.log('here3');
  //     logout('Your session has expired');
  //   }
  // }, [location.pathname, logout]);

  return {
    login,
    logout,
    createPassword,
    sendResetPasswordEmail,
  };
};

export const AuthProvider = ({ children }) => {
  const authProvider = useProviderAuth();
  return <authContext.Provider value={authProvider}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
