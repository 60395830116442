import { Checkbox } from '@material-ui/core';
import React from 'react';

import RowLayout from '../../../layout/RowLayout';
import InputForm from '../../UI/InputForm';
import TitleRow from '../TitleRow';
import { styles } from './styles';

const InputRow = ({
  title,
  className,
  titleHint,
  name,
  control,
  rules,
  props,
  inputProps,
  passHint,
  unlimited,
  size,
}) => {
  const classes = styles();

  return (
    <RowLayout>
      {title || titleHint ? (
        <TitleRow>
          {title} <p>{titleHint}</p>
        </TitleRow>
      ) : null}
      <InputForm
        className={className}
        size={size}
        name={name}
        control={control}
        rules={rules}
        props={props}
        inputProps={inputProps}
      />
      {!!unlimited && (
        <div className={classes.unlimitedCheckbox}>
          <Checkbox color="info" />
          Unlimited
        </div>
      )}
      {!!passHint && (
        <div className={classes.passHint}>
          <ul>
            <li>Minimum of 8 characters</li>
            <li>
              <p>At least one number or symbol</p>
              <p>{'(~!@#$%^&*_-+=`|\\(){}[]:;\'"<>,.?/)'}</p>
            </li>
            <li>At least one uppercase symbol A-Z</li>
            <li>Do not use email address</li>
            <li>Do not use previous passwords</li>
          </ul>
        </div>
      )}
    </RowLayout>
  );
};
export default InputRow;
