import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetPrograms($filters: ProgramFilterInput) {
    programs(filters: $filters) {
      id
      title
      rank
      genre {
        name
      }
      conditions {
        name
      }
      status
      isNew
    }
    programTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetProgram($id: String!) {
    program(id: $id) {
      id
      title
      subTitle
      description
      image {
        id
        url
      }
      genre {
        id
        name
      }
      conditions {
        id
        name
      }
      rank
      status
      isNew
      steps {
        id
        title
        subTitle
        books {
          id
          title
        }
        articles {
          id
          title
        }
        videos {
          id
          title
        }
        audios {
          id
          title
        }
      }
    }
  }
`;

const createMutation = gql`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      id
      title
    }
  }
`;

const updateMutation = gql`
  mutation UpdateProgram($id: String!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
      id
      title
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteProgram($id: String!) {
    deleteProgram(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportPrograms($filters: ProgramFilterInput) {
    exportPrograms(filters: $filters) {
      token
      link
    }
  }
`;

export default class ProgramApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportPrograms',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.programs,
        total: data.programTotals,
      }),
    });
  }
}
