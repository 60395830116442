import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  unlimitedCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
  passHint: {
    position: 'absolute',
    top: '0',
    left: 'calc(100% + 32px)',
    width: '375px',
    color: ' #555555',
    padding: '10px 24px',
    border: '1px solid #e4e4e4',
    backgroundColor: '#f2f2f2',
    margin: '0 0 82px 0',
    '& ul, & li': {
      listStyle: 'initial',
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
}));
