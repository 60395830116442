import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetConditions($filters: ConditionAdminFilterInput) {
    conditionsAdmin(filters: $filters) {
      id
      name
      category
      status
      bookCount
      articleCount
      audioCount
      videoCount
    }
    conditionTotalsAdmin {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetCondition($id: String!) {
    conditionAdmin(id: $id) {
      id
      name
      category
      status
      bookCount
      articleCount
      audioCount
      videoCount
      image {
        id
        url
      }
    }
  }
`;

const createMutation = gql`
  mutation CreateCondition($input: CreateConditionInput!) {
    createCondition(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateCondition($id: String!, $input: UpdateConditionInput!) {
    updateCondition(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteCondition($id: String!) {
    deleteCondition(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportConditions($filters: ConditionAdminFilterInput) {
    exportConditions(filters: $filters) {
      token
      link
    }
  }
`;

export default class ConditionApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportConditions',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.conditionsAdmin,
        total: data.conditionTotalsAdmin,
      }),
    });
  }
}
