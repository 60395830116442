import React from 'react';
import { Controller } from 'react-hook-form';

import Input from '../Input';

const InputForm = ({ name, className, control, size, rules = {}, props = {}, inputProps = {} }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, onChange, value } }) => (
        <Input
          inputRef={ref}
          className={className}
          value={value}
          size={size}
          onChange={(e) => onChange(e, props)}
          inputProps={inputProps}
          props={props}
        />
      )}
    />
  );
};
export default InputForm;
