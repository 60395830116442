import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetBookPackages($filters: BookPackageAdminFilterInput) {
    bookPackagesAdmin(filters: $filters) {
      id
      name
      company {
        id
        name
      }
      companyId
      companyDomain
      accessType
      numberOfLicenses
      expirationDate
      creationDate
      packageType
      status
      genres {
        id
        name
      }
      subgenres {
        id
        name
      }
    }
    bookPackageTotalsAdmin {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetBookPackage($id: String!) {
    bookPackageAdmin(id: $id) {
      id
      name
      companyId
      companyDomain
      accessType
      numberOfLicenses
      expirationDate
      creationDate
      packageType
      userCount
      bookCount
      articles {
        id
        title
      }
      audios {
        id
        title
      }
      books {
        id
        title
      }
      videos {
        id
        title
      }
      genres {
        id
        name
      }
      subgenres {
        id
        name
      }
      userInvites {
        id
        email
        status
      }
      bundles {
        id
        name
      }
    }
  }
`;

const createMutation = gql`
  mutation CreateBookPackage($input: CreateBookPackageInput!) {
    createBookPackage(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateBookPackage($id: String!, $input: UpdateBookPackageInput!) {
    updateBookPackage(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteBookPackage($id: String!) {
    deleteBookPackage(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportBookPackages($filters: BookPackageAdminFilterInput) {
    exportBookPackages(filters: $filters) {
      token
      link
    }
  }
`;

export default class BookPackageApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportBookPackages',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.bookPackagesAdmin,
        total: data.bookPackageTotalsAdmin,
      }),
    });
  }
}
