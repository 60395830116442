import TableIcon from '@mui/icons-material/BackupTable';
import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { styles } from './styles';

const PageTitle = ({
  title,
  href,
  linkTitle,
  actionTitle,
  actionIcon,
  actionHandler,
  actionDisabled,
  withoutAction,
  withExport,
}) => {
  const classes = styles();

  return (
    <section className={classes.pageTitle}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.buttons}>
        {withExport ? (
          <Button
            disabled={actionDisabled}
            onClick={() => actionHandler()}
            startIcon={<TableIcon />}
            size="large"
            variant="outlined"
          >
            Export .xls
          </Button>
        ) : null}
        {withoutAction ? null : (
          <>
            {href ? (
              <Link className={classes.link} to={href}>
                <Button size="large" variant="contained">
                  {linkTitle}
                </Button>
              </Link>
            ) : null}
            {actionTitle ? (
              <Button
                disabled={actionDisabled}
                onClick={() => actionHandler()}
                startIcon={actionIcon}
                size="large"
                variant="contained"
              >
                {actionTitle}
              </Button>
            ) : null}
          </>
        )}
      </div>
    </section>
  );
};
export default PageTitle;
