import { gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { client } from '../apollo';

const loginMutation = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

const createPasswordMutation = gql`
  mutation CreatePassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

const sendResetPasswordEmailMutation = gql`
  mutation SendResetPasswordEmail($input: SendPasswordResetEmailInput!) {
    sendResetPasswordEmail(input: $input)
  }
`;

export default class AuthApolloService {
  async login(data) {
    try {
      const res = await client.mutate({
        mutation: loginMutation,
        variables: { input: data },
      });

      toast.success(`You have successfully logged in`);

      return { data: res.data };
    } catch (e) {
      toast.error(`Login error: ${e?.message}`);
    }
  }

  async createPassword(data) {
    try {
      const res = await client.mutate({
        mutation: createPasswordMutation,
        variables: { input: data },
      });

      toast.success(`You have successfully created password`);

      return { data: res.data };
    } catch (e) {
      toast.error(`Create password error: ${e?.message}`);
    }
  }

  async sendResetPasswordEmail(data) {
    try {
      const res = await client.mutate({
        mutation: sendResetPasswordEmailMutation,
        variables: { input: data },
      });

      toast.success(`Check reset link was sent to your email`);

      return { data: res.data };
    } catch (e) {
      toast.error(`Reset password error: ${e?.message}`);
    }
  }
}
